import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/Layout";
import Header from "../../components/Header";
import BlogList from "../../components/BlogList";

export default function Index({
  data: {
    contentfulNewsPage,
    contentfulNavigationBar,
    contentfulFooter,
  },
}) {
  const data = contentfulNewsPage;
  return (
    <Layout
      footerElements={contentfulFooter}
    >
      <Header
        heading={data.heading}
        subheading={data.subheading}
        buttonList={data.buttonList}
        vanish={true}
        image={data.headerImage}
        navBar={contentfulNavigationBar.navigationBarList}
      />
      <BlogList contentPages={data.listedContentPages} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulNewsPage(node_locale: { eq: "de" }) {
      ...HeaderWithoutButtonsNews
      listedContentPages {
        headerImage {
          gatsbyImageData
        }
        slug
        heading
        date
        durationInMinutes
        redirect
        redirectLink
        summary {
          raw
        }
      }
    }
    contentfulNavigationBar(node_locale: { eq: "de" }) {
      navigationBarList {
        linksToSlug
        title
      }
    }
    contentfulFooter(node_locale: { eq: "de" }) {
      footerElements {
        linksToSlug
        title
      }
    }
  }
`;
